const getAudioStream = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        return stream;
    } catch (err) {
        throw new Error("Error accessing the microphone.");
    }
};

export default getAudioStream;
