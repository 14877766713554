import React from "react";
import pause from "./pause.svg";
import microphone from "./microphone.svg";
import spinner from "./spinner.svg";
import {RecordState} from "../types/record.d";
import "./Icon.css";

const Icon = ({recordState}: IconProps) => {
    const icons = {
        [RecordState.paused]: pause,
        [RecordState.recording]: microphone,
        [RecordState.loading]: spinner,
    };

    const icon = icons[recordState];

    if (!icon) {
        throw new Error(`Unknown record state: ${recordState}`);
    }

    return (
        <>
            <img className="icon" src={icon} alt="icon" width={100} />
        </>
    );
};

export interface IconProps {
    recordState: RecordState;
}

export default Icon;
