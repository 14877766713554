const startRecording = (stream: MediaStream, websocket: WebSocket) => {
    const mediaRecorder = new MediaRecorder(stream);
    const audioChunks: Blob[] = [];

    mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
        // Send each chunk to the server as it becomes available
        if (websocket.readyState === WebSocket.OPEN) {
            websocket.send(event.data);
        }
    };

    mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, {type: "audio/wav"});
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        // audio.play();
    };

    mediaRecorder.start();

    return mediaRecorder;
};

export default startRecording;
